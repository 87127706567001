import React from 'react';

import Avatar from 'components/Avatar';
import { DASH } from 'config/constants';
import {
  allPersonalInterestsLabels,
  allSkillsLabels,
  PersonalInterest,
  Skill,
  TeacherStatus,
} from 'config/teacherProfiles';
import { ITeacher } from 'model/profiles';
import { useEvcTunnelForTeacher } from 'network/Classroom';
import { useTeacherCenterById } from 'network/graphql/Departments';
import { getEnglishFullLangName } from 'utils/miscellaneous';

import {
  ChipTypeA,
  ChipTypeB,
  GreenCircle,
  Hr,
  Label,
  ListContainer,
  YellowCircle,
  Text,
  ContentWrapper,
  EditProfileLink,
  ProfileName,
  RedCircle,
} from './styledComponents';

interface ViewContentProps {
  profile: ITeacher;
  switchToEditMode(): void;
}

export const ViewContent: React.FC<ViewContentProps> = ({ profile, switchToEditMode }) => {
  const teacherCenterName = useTeacherCenterById(profile?.departmentId)?.name;
  const { data: evcProxy } = useEvcTunnelForTeacher(profile.id);
  return (
    <ContentWrapper>
      {RenderBasics(
        profile.firstName,
        profile.lastName,
        profile.status,
        teacherCenterName,
        <EditProfileLink key="edit-button" onClick={switchToEditMode}>
          Edit
        </EditProfileLink>,
      )}
      <Hr />
      {renderLabeledValue('Email', profile.email)}
      {renderList(
        'Personal Interests',
        profile.personalInterests || [],
        (el) => allPersonalInterestsLabels[el as PersonalInterest],
      )}
      {renderList('Skills', profile.skills || [], (el) => allSkillsLabels[el as Skill])}
      {renderList('Spoken Languages', profile.instructionLanguages, getEnglishFullLangName)}
      {renderList('EVC China Proxy', evcProxy?.description ? [evcProxy.description] : [])}
    </ContentWrapper>
  );
};

export const renderAvatar = (firstName: string, lastName: string) => (
  <Avatar
    initials={`${firstName.charAt(0)}${lastName.charAt(0)}`.toLocaleUpperCase() || ''}
    size={80}
    fontSize={22}
    fontColor="#191919"
    avatar="profile"
  />
);

export const RenderBasics = (
  firstName: string,
  lastName: string,
  status: TeacherStatus,
  center: string | undefined,
  EditButton?: JSX.Element,
) => (
  <div style={{ display: 'flex' }}>
    {renderAvatar(firstName, lastName)}
    <div style={{ width: 400, marginLeft: 16 }}>
      <ProfileName>
        {firstName ?? DASH} {lastName ?? DASH}
        {EditButton}
      </ProfileName>
      <div style={{ marginTop: 5, transform: 'translateY(4px)' }}>
        {status === TeacherStatus.ACTIVE ? (
          <>
            <GreenCircle />
            Active
          </>
        ) : status === TeacherStatus.PENDING_DOWNGRADE ? (
          <>
            <YellowCircle />
            Pending deactivation
          </>
        ) : (
          <>
            <RedCircle />
            Deactivated
          </>
        )}
      </div>
      <div style={{ display: 'flex', marginTop: 16 }}>
        <ChipTypeA>{center || DASH}</ChipTypeA>
      </div>
    </div>
  </div>
);

export const renderList = (label: string, list: string[], transformer?: (arg: string) => string) => (
  <>
    <Label>{label}</Label>
    <ListContainer>
      {list?.length === 0 ? (
        <Text>{DASH}</Text>
      ) : (
        list.map((item) => <ChipTypeB key={item}>{transformer ? transformer(item) : item}</ChipTypeB>)
      )}
    </ListContainer>
  </>
);

export const renderLabeledValue = (label: string, value: string) => (
  <>
    <Label>{label}</Label>
    <Text>{value}</Text>
  </>
);
